// This file is to change variable values and default styles of bootstrap
$primary: #ff6c21;
$secondary: #272738;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
);

$nav-tabs-link-active-bg: #0d0d18a1;
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-link-hover-border-color: transparent;
$form-range-track-bg: #00000099;

$form-range-thumb-width: 1.3rem;
$form-range-thumb-height: 1.3rem;
$form-range-track-height: 0.875rem;
$form-range-track-box-shadow: none;
$form-range-thumb-focus-box-shadow: none;

$nav-tabs-border-radius: 12px;
$nav-tabs-border-width: 0px;
$offcanvas-bg-color: #0d0d18;
$offcanvas-horizontal-width: 300px;

.form-range {
  &::-webkit-slider-thumb {
    background-image: linear-gradient(96deg, #ff6c21 0%, #403fd8 100%);
  }

  &::-moz-range-thumb {
    background-image: linear-gradient(96deg, #ff6c21 0%, #403fd8 100%);
  }
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
